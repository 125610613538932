<template>
  <div class="couponList-page">
    <div class="flexbox flex-lr flex-align-center flex-wrap">
      <div class="search-item">
        <span>活动编号</span>
        <el-input v-model="form.activity_ticket_id" placeholder="请输入活动编号" style="width:150px;" clearable></el-input>
      </div>
      <div class="search-item">
        <span>优惠券名称</span>
        <el-input v-model="form.coupon_name" placeholder="请输入优惠券名称" style="width:200px;" clearable></el-input>
      </div>
      <div class="search-item">
        <span>优惠券状态</span>
        <el-select v-model="form.status" clearable placeholder="优惠券状态" style="width:150px;">
          <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <el-button class="search-btn" type="primary" @click="search">搜索</el-button>
      <el-button class="search-btn" type="info" @click="clear">清空</el-button>
      <el-button class="search-btn" type="primary" @click="navAddCouponPage(null, '1')">新增优惠券</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="activity_ticket_id" label="活动编号" align="center" width="200"></el-table-column>
        <el-table-column prop="coupon_name" label="优惠券名称" align="center"></el-table-column>
        <el-table-column prop="area" label="适用商圈" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.area">{{scope.row.area.trading_area_name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="contactTel" label="活动周期" align="center" width="310">
          <template slot-scope="scope">
            <span>{{scope.row.start_time}}至{{scope.row.dead_time}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="received" label="已领取" align="center"></el-table-column>
        <el-table-column prop="used" label="已使用" align="center"></el-table-column>
        <el-table-column prop="left" label="剩余" align="center"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="160" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">进行中</span>
            <span v-if="scope.row.status == 2">未生效</span>
            <span v-if="scope.row.status == 3">已停用</span>
            <span v-if="scope.row.status == 4">已失效</span>
          </template>
        </el-table-column>
        <el-table-column prop="shopFinance" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.status == 1" @click="shareCoupon(scope.row)">分享</el-button>
            <el-button type="text" v-if="scope.row.status != 2" @click="navCouponDetailPage(scope.row.activity_ticket_id)">详情</el-button>
            <el-button type="text" v-if="scope.row.status == 1" @click="changeStatus(scope.row.activity_ticket_id, 1)">停用</el-button>
            <el-button type="text" v-if="scope.row.status == 3" @click="changeStatus(scope.row.activity_ticket_id, 2)">启用</el-button>
            <el-button type="text" v-if="scope.row.status == 2" @click="navAddCouponPage(scope.row.activity_ticket_id, '2')">编辑</el-button>
            <el-button type="text" @click="navAddCouponPage(scope.row.activity_ticket_id, '3')">复制</el-button>
            <el-button type="text" v-if="scope.row.status != 1" @click="changeStatus(scope.row.activity_ticket_id, 3)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination
      class="mt10" 
      background 
      :page-count="totalPage" 
      :current-page.sync="currentPage" 
      @current-change="pageChange">
    </el-pagination>
    <!-- 分享优惠券弹窗 -->
    <ShareCouponDialog ref="shareCouponDialog" />
  </div>
</template>
<script>
import { $sg_platCouponList, $sp_saveStatus } from '@/api/schoolGroupShops';
import ShareCouponDialog from './components/shareCouponDialog.vue';

export default {
  components: {
    ShareCouponDialog,
  },
  data() {
    return {
      form: {
        activity_ticket_id: '',
        coupon_name: '',
        status: '',
      },
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '进行中',
        },
        {
          value: 2,
          label: '未生效',
        },
        {
          value: 3,
          label: '已停用',
        },
        {
          value: 4,
          label: '已失效',
        },
      ],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      Object.keys(this.form).forEach((val) => {
        this.form[val] = '';
      });
      this.search();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        page: this.currentPage,
        page_size: 10,
        ...this.form,
      };
      $sg_platCouponList(params).then((res) => {
        this.tableData = res.list;
        this.totalPage = res.total_page;
      });
    },
    // 跳转到新增优惠券
    navAddCouponPage(activity_ticket_id, type) {
      if (activity_ticket_id) {
        this.$router.push({
          name: 'AddCoupon',
          query: {
            activity_ticket_id,
            type,
          },
        });
      } else {
        this.$router.push({
          name: 'AddCoupon',
        });
      }
    },
    // 跳转到优惠券详情页
    navCouponDetailPage(activity_ticket_id) {
      this.$router.push({
        name: 'CouponDetail',
        query: {
          activity_ticket_id,
        },
      });
    },
    // 分享优惠券
    shareCoupon(row) {
      this.$refs.shareCouponDialog.open(row);
    },
    // 启用/禁用/删除
    changeStatus(activity_ticket_id, status) {
      let title = '';
      let tips = '';
      if (+status === 1) {
        title = '停用活动';
        tips = '停用后用户端不能再推送此活动消息，已领券用户不受影响，确认停用活动?';
      } else if (+status === 2) {
        title = '启用活动';
        tips = '启用后用户端将恢复推送，确认启用活动?';
      } else if (+status === 3) {
        title = '删除活动';
        tips = '删除活动后活动自动失效，并且无法恢复，确认删除活动?';
      }
      this.$confirm(tips, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
        center: true,
      }).then(() => {
        const params = {
          id: activity_ticket_id,
          status,
        };
        $sp_saveStatus(params).then(() => {
          this.search();
        });
      }).catch(() => {});
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.couponList-page {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .search-item {
    margin-right: 20px;
    margin-top: 20px;
    & > span {
      color: #666;
      margin-right: 5px;
    }
  }
  .search-btn {
    margin-top: 20px;
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    min-height: 580px;
  }
}
</style>

