<template>
    <el-dialog
      title="活动分享"
      :visible.sync="dialogVisible"
      center
      width="500px">
      <div class="item">
        <span class="title">活动链接</span>
        <span class="link">{{shareUrl}}</span>
      </div>
      <div class="item">
        <span class="title">活动名称</span>
        <span class="link">{{name}}</span>
      </div>
      <div class="qrcode-box">
        <img v-if="qrcodeUrl" :src="qrcodeUrl" alt="">
        <el-button type="primary" @click="downLoadQrcode">下载</el-button>
      </div>
      <div class="flexbox flex-justify-center mt20">
        <el-button type="primary" @click="copyLink">复制链接</el-button>
      </div>
    </el-dialog>
</template>
<script>
import { copyStringCom, exportFileSync } from '@base/utils';
import { getQrcodeSrc } from '@/global/globalMethods';


export default {
  data() {
    return {
      dialogVisible: false,
      name: '',
      shareUrl: '',
      qrcodeUrl: '',
    };
  },
  methods: {
    // 打开弹窗
    open(row) {
      this.name = row.coupon_name;
      this.shareUrl = `${process.env.VUE_APP_WAP_DOMAIN}/activity/special/share/${row.activity_ticket_id}`;
      this.qrcodeUrl = getQrcodeSrc(this.shareUrl);
      this.dialogVisible = true;
    },
    // 下载二维码
    downLoadQrcode() {
      exportFileSync(
        this.qrcodeUrl,
        {},
        'post',
        '二维码.png',
      ).then(() => {});
    },
    // 复制链接
    copyLink() {
      copyStringCom(this.shareUrl, () => {
        this.$message.success('复制成功');
        this.dialogVisible = false;
      }, () => {
        this.$message.error('复制失败');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .item {
    margin-top: 20px;
    .title {
      font-size: 16px;
    }
    .link {
      margin: 0px 10px;
    font-size: 14px;
    }
  }
  .qrcode-box {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
</style>
